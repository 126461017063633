body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #212529;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 768px) {
  input.form-control.form-control-sm {
    font-size: 1.5rem;
  }
  textarea.form-control {
    font-size: 1.5rem;
  }
}

pre {
  font-family: var(--font-family-sans-serif) !important;
  white-space: pre-wrap;
}

.bcb-bg-transparent {
  background-color: rgba(53, 59, 65, 0.8);
}
